import { useEffect, useState } from 'react';
import style from './index.module.scss';
import Layout from '../Layout';
import Block1 from './Block1';
import Block2 from './Block2';
import Block3 from './Block3';
import Block4 from './Block4';
import { post } from 'common/utils';
import moment from 'moment';

const Page = (props) => {
    const [datetime] = useState(() => {
        const currTime = moment()
        if (parseInt(currTime.format("HH")) >= 12) {
            return currTime.subtract(1, 'days').format("YYYY-MM-DD")
        } else {
            return currTime.subtract(2, 'days').format("YYYY-MM-DD")
        }
    })
    const [data, setData] = useState(null);
    useEffect(() => {
        post(process.env.REACT_APP_SERVER_PATH + "conch_wuhu/get_eco2", {
            day: datetime,
            server_env: 0,
        })
            .then(retData => {
                let n_data = null;
                if (retData?.data?.errcode === 0) {
                    n_data = retData?.data?.result ?? null;
                };
                setData(n_data);
            });
    }, [datetime]);

    return (
        <Layout
            title="碳管家"
            width={2688}
            height={1728}
        >
            <div className={style['wrapper']}>
                <div className="block_1 left">
                    <Block1 data={data?.L05_ECO2 ?? null} />
                </div>
                <div className="block_1 right">
                    <Block2 data={data?.L05_ECO2 ?? null} chartData={data?.L05_month_chart ?? null} />
                </div>
                <div className="block_2 left">
                    <Block3 data={data?.L05_day_chart ?? null} />
                </div>
                <div className="block_2 right">
                    <Block4 data={data?.L05_day_chart ?? null} />
                </div>
            </div>
        </Layout>
    );
};

export default Page;
