import Block1 from './Block1';
import BlockQlty from './BlockQlty';
import BlockElec from './BlockElec';
import ICON_PROD from './res/prod.svg';
import ICON_COST from './res/cost.svg';
import ICON_ELEC from './res/elec.svg';
import ICON_QLTY from './res/qlty.svg';
import ICON_ALARM from './res/alarm.svg';
import ContentLayout from './ContentLayout';

const getBlocks = (datas) => {
    return [
        {
            name: "prod",
            info: {
                icon: ICON_PROD,
                title: "生产概况",
                Comp: Block1,
                props: {
                    data: datas?.[0] ?? null,
                    list: [
                        {
                            name: "熟料",
                            keys: [
                                {
                                    name: "产量",
                                    key: "熟料产量",
                                    unit: "t",
                                },
                                {
                                    name: "台时",
                                    key: "熟料台时",
                                    unit: "t/h",
                                },
                            ],
                            doubleYAxis: true,
                        },
                        {
                            name: "5A原料磨",
                            keys: [
                                {
                                    name: "产量",
                                    key: "5A原料磨产量",
                                    unit: "t",
                                },
                                {
                                    name: "台时",
                                    key: "5A原料磨台时",
                                    unit: "t/h",
                                },
                            ],
                            doubleYAxis: true,
                        },
                        {
                            name: "5B原料磨",
                            keys: [
                                {
                                    name: "产量",
                                    key: "5B原料磨产量",
                                    unit: "t",
                                },
                                {
                                    name: "台时",
                                    key: "5B原料磨台时",
                                    unit: "t/h",
                                },
                            ],
                            doubleYAxis: true,
                        },
                        {
                            name: "5A煤磨",
                            keys: [
                                {
                                    name: "产量",
                                    key: "5A煤磨产量",
                                    unit: "t",
                                },
                                {
                                    name: "台时",
                                    key: "5A煤磨台时",
                                    unit: "t/h",
                                },
                            ],
                            doubleYAxis: true,
                        },
                        {
                            name: "5B煤磨",
                            keys: [
                                {
                                    name: "产量",
                                    key: "5B煤磨产量",
                                    unit: "t",
                                },
                                {
                                    name: "台时",
                                    key: "5B煤磨台时",
                                    unit: "t/h",
                                },
                            ],
                            doubleYAxis: true,
                        },
                    ],
                },
            },
        },
        {
            name: "cost",
            info: {
                icon: ICON_COST,
                title: "能耗概况",
                Comp: Block1,
                props: {
                    data: datas?.[1] ?? null,
                    list: [
                        {
                            name: "熟料电耗",
                            keys: [
                                {
                                    name: "煤耗",
                                    key: "熟料煤耗",
                                    unit: "kg/t",
                                },
                                {
                                    name: "电耗",
                                    key: "熟料电耗",
                                    unit: "kW.h/t",
                                },
                            ],
                            doubleYAxis: true,
                        },
                        {
                            name: "原料磨电耗",
                            keys: [
                                {
                                    name: "5A",
                                    key: "5A原料磨电耗",
                                    unit: "kW.h/t",
                                },
                                {
                                    name: "5B",
                                    key: "5B原料磨电耗",
                                    unit: "kW.h/t",
                                },
                            ],
                        },
                        {
                            name: "煤磨电耗",
                            keys: [
                                {
                                    name: "5A",
                                    key: "5A煤磨电耗",
                                    unit: "kW.h/t",
                                },
                                {
                                    name: "5B",
                                    key: "5B煤磨电耗",
                                    unit: "kW.h/t",
                                },
                            ],
                        },
                        {
                            name: "脱硫电耗",
                            keys: [
                                {
                                    name: "",
                                    key: "脱硫电耗",
                                    unit: "kW.h/t",
                                },
                            ],
                        },
                    ],
                },
            },
        },
        {
            name: "elec",
            info: {
                icon: ICON_ELEC,
                title: "用电统计",
                Comp: BlockElec,
                props: {
                    data: datas?.[2] ?? null,
                    barTitle: "用电量小时趋势",
                },
            },
        },
        {
            name: "qlty",
            info: {
                icon: ICON_QLTY,
                title: "质量概况",
                Comp: BlockQlty,
                props: {
                    data: datas?.[3] ?? null,
                },
            },
        },
        {
            name: "pwrgen",
            info: {
                icon: ICON_ALARM,
                title: "发电统计",
                Comp: BlockElec,
                props: {
                    data: datas?.[4] ?? null,
                    barTitle: "发电量小时趋势",
                },
            },
        },
    ]
        .map(item => {
            const { info: { icon, title, Comp, props } } = item;
            return Object.assign({}, item, {
                Comp: (
                    <ContentLayout icon={icon} title={title}>
                        {
                            !!Comp && (
                                <Comp {...(props ?? {})} />
                            )
                        }
                    </ContentLayout>
                ),
            });
        })
        .reduce((pV, cV) => {
            return Object.assign(pV, {
                [cV.name]: cV.Comp,
            });
        }, {});
};

export {
    getBlocks,
};
