import { significantNum } from "common/utils";

const getColumns = () => {
    return [
        {
            title: "指标名称",
            dataIndex: "kpi_name",
        },
        {
            title: "单位",
            dataIndex: "unit",
        },
        {
            title: "标准值",
            dataIndex: "std",
        },
        {
            title: "指标均值",
            dataIndex: "d_avg",
            render: t => {
                const p_t = typeof t === "number" ? significantNum(t) : "--";
                return p_t;
            },
        },
        {
            title: "健康度",
            dataIndex: "d_kpx",
            render: t => {
                const p_t = typeof t === "number" ? significantNum(t) : "--";
                return p_t;
            },
        },
        {
            title: () => {
                return (
                    <div>（晚班）过程</div>
                );
            },
            dataIndex: "shift3_klist",
            render: t => {
                if (!!t && t.length === 8) {
                    return (
                        <div style={{ height: 20, display: 'flex' }}>
                            {
                                t.map((item, index) => {
                                    const backgroundColor = item === 1 ? "rgba(63, 202, 241, 1)" : (item === 0 ? "#0000EB" : "transparent");
                                    return (
                                        <div style={{ width: 0, flexGrow: 1, backgroundColor }} key={index}></div>
                                    );
                                })
                            }
                        </div>
                    );
                };
                return null;
            },
        },
        {
            title: "均值",
            dataIndex: "shift3_avg",
            render: t => {
                const p_t = typeof t === "number" ? significantNum(t) : "--";
                return p_t;
            },
        },
        {
            title: "健康度",
            dataIndex: "shift3_kavg",
            render: t => {
                const p_t = typeof t === "number" ? significantNum(t) : "--";
                return p_t;
            },
        },
        {
            title: () => {
                return (
                    <div>（早班）过程</div>
                );
            },
            dataIndex: "shift1_klist",
            render: t => {
                if (!!t && t.length === 8) {
                    return (
                        <div style={{ height: 20, display: 'flex' }}>
                            {
                                t.map((item, index) => {
                                    const backgroundColor = item === 1 ? "rgba(63, 202, 241, 1)" : (item === 0 ? "#0000EB" : "transparent");
                                    return (
                                        <div style={{ width: 0, flexGrow: 1, backgroundColor }} key={index}></div>
                                    );
                                })
                            }
                        </div>
                    );
                };
                return null;
            },
        },
        {
            title: "均值",
            dataIndex: "shift1_avg",
            render: t => {
                const p_t = typeof t === "number" ? significantNum(t) : "--";
                return p_t;
            },
        },
        {
            title: "健康度",
            dataIndex: "shift1_kavg",
            render: t => {
                const p_t = typeof t === "number" ? significantNum(t) : "--";
                return p_t;
            },
        },
        {
            title: () => {
                return (
                    <div>（中班）过程</div>
                );
            },
            dataIndex: "shift2_klist",
            render: t => {
                if (!!t && t.length === 8) {
                    return (
                        <div style={{ height: 20, display: 'flex' }}>
                            {
                                t.map((item, index) => {
                                    const backgroundColor = item === 1 ? "rgba(63, 202, 241, 1)" : (item === 0 ? "#0000EB" : "transparent");
                                    return (
                                        <div style={{ width: 0, flexGrow: 1, backgroundColor }} key={index}></div>
                                    );
                                })
                            }
                        </div>
                    );
                };
                return null;
            },
        },
        {
            title: "均值",
            dataIndex: "shift2_avg",
            render: t => {
                const p_t = typeof t === "number" ? significantNum(t) : "--";
                return p_t;
            },
        },
        {
            title: "健康度",
            dataIndex: "shift2_kavg",
            render: t => {
                const p_t = typeof t === "number" ? significantNum(t) : "--";
                return p_t;
            },
        },
    ];
};

const mockData = {
    "errcode": 0,
    "result": [
        {
            "d_avg": 13639.554166666667,
            "d_kpx": 1,
            "kpi_name": "熟料烧成5#线电功率",
            "shift1_avg": 13772.037499999999,
            "shift1_kavg": 1,
            "shift1_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift2_avg": 13459.4875,
            "shift2_kavg": 1,
            "shift2_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift3_avg": 13687.137499999999,
            "shift3_kavg": 1,
            "shift3_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "std": 14300,
            "tag": "SLSC05_ERATE",
            "unit": "kW"
        },
        {
            "d_avg": 74190.04166666667,
            "d_kpx": 0.9166666666666666,
            "kpi_name": "熟料烧成5#线投煤量",
            "shift1_avg": 73989.875,
            "shift1_kavg": 0.875,
            "shift1_klist": [
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                1
            ],
            "shift2_avg": 72348.25,
            "shift2_kavg": 1,
            "shift2_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift3_avg": 76232,
            "shift3_kavg": 0.875,
            "shift3_klist": [
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                1
            ],
            "std": 80600,
            "tag": "SLSC05_CRATE",
            "unit": "t/h"
        },
        {
            "d_avg": 505.92241666666655,
            "d_kpx": 0.6666666666666666,
            "kpi_name": "熟料烧成5#线台时",
            "shift1_avg": 503.9312500000001,
            "shift1_kavg": 0,
            "shift1_klist": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "shift2_avg": 506.839375,
            "shift2_kavg": 1,
            "shift2_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift3_avg": 506.99662500000005,
            "shift3_kavg": 1,
            "shift3_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "std": 10000,
            "tag": "SLSC05_PRATE",
            "unit": "t/h"
        },
        {
            "d_avg": 26.960408333333334,
            "d_kpx": 1,
            "kpi_name": "熟料烧成5#线电单耗",
            "shift1_avg": 27.329075,
            "shift1_kavg": 1,
            "shift1_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift2_avg": 26.555762500000004,
            "shift2_kavg": 1,
            "shift2_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift3_avg": 26.996387499999997,
            "shift3_kavg": 1,
            "shift3_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "std": 28,
            "tag": "SLSC05_ECONS",
            "unit": "kW/t"
        },
        {
            "d_avg": 146.64245833333334,
            "d_kpx": 0.7083333333333334,
            "kpi_name": "熟料烧成5#线煤单耗",
            "shift1_avg": 146.82725,
            "shift1_kavg": 0.75,
            "shift1_klist": [
                1,
                1,
                0,
                1,
                1,
                1,
                0,
                1
            ],
            "shift2_avg": 142.73800000000003,
            "shift2_kavg": 0.75,
            "shift2_klist": [
                1,
                0,
                1,
                1,
                0,
                1,
                1,
                1
            ],
            "shift3_avg": 150.362125,
            "shift3_kavg": 0.625,
            "shift3_klist": [
                1,
                1,
                0,
                1,
                1,
                0,
                1,
                0
            ],
            "std": 150,
            "tag": "SLSC05_CCONS",
            "unit": "kg/t"
        },
        {
            "d_avg": 6798.00125,
            "d_kpx": 0.4166666666666667,
            "kpi_name": "原料粉磨5A线电功率",
            "shift1_avg": 6717.69375,
            "shift1_kavg": 0.625,
            "shift1_klist": [
                1,
                0,
                1,
                1,
                0,
                1,
                1,
                0
            ],
            "shift2_avg": 6923.5075,
            "shift2_kavg": 0.25,
            "shift2_klist": [
                0,
                1,
                0,
                0,
                0,
                1,
                0,
                0
            ],
            "shift3_avg": 6752.8025,
            "shift3_kavg": 0.375,
            "shift3_klist": [
                1,
                0,
                0,
                0,
                1,
                0,
                0,
                1
            ],
            "std": 6850,
            "tag": "YLFM5A_ERATE",
            "unit": "kW"
        },
        {
            "d_avg": 504.27870833333344,
            "d_kpx": 0,
            "kpi_name": "原料粉磨5A线台时",
            "shift1_avg": 504.21812500000004,
            "shift1_kavg": 0,
            "shift1_klist": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "shift2_avg": 504.15737500000006,
            "shift2_kavg": 0,
            "shift2_klist": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "shift3_avg": 504.46062500000005,
            "shift3_kavg": 0,
            "shift3_klist": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "std": 10000,
            "tag": "YLFM5A_PRATE",
            "unit": "t/h"
        },
        {
            "d_avg": 13.480629166666665,
            "d_kpx": 0.875,
            "kpi_name": "原料粉磨5A线电单耗",
            "shift1_avg": 13.3231125,
            "shift1_kavg": 0.875,
            "shift1_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0
            ],
            "shift2_avg": 13.732587500000001,
            "shift2_kavg": 0.875,
            "shift2_klist": [
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                1
            ],
            "shift3_avg": 13.386187499999998,
            "shift3_kavg": 0.875,
            "shift3_klist": [
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                1
            ],
            "std": 14,
            "tag": "YLFM5A_ECONS",
            "unit": "kW/t"
        },
        {
            "d_avg": 6847.395833333335,
            "d_kpx": 0.4583333333333333,
            "kpi_name": "原料粉磨5B线电功率",
            "shift1_avg": 6587.351250000001,
            "shift1_kavg": 0.875,
            "shift1_klist": [
                1,
                0,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift2_avg": 6976.283750000001,
            "shift2_kavg": 0.25,
            "shift2_klist": [
                0,
                0,
                0,
                1,
                0,
                0,
                0,
                1
            ],
            "shift3_avg": 6978.552500000001,
            "shift3_kavg": 0.25,
            "shift3_klist": [
                0,
                0,
                1,
                0,
                0,
                0,
                1,
                0
            ],
            "std": 6850,
            "tag": "YLFM5B_ERATE",
            "unit": "kW"
        },
        {
            "d_avg": 504.50129166666665,
            "d_kpx": 0.375,
            "kpi_name": "原料粉磨5B线台时",
            "shift1_avg": 507.94674999999995,
            "shift1_kavg": 1,
            "shift1_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift2_avg": 502.1875,
            "shift2_kavg": 0.125,
            "shift2_klist": [
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "shift3_avg": 503.369625,
            "shift3_kavg": 0,
            "shift3_klist": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "std": 10000,
            "tag": "YLFM5B_PRATE",
            "unit": "t/h"
        },
        {
            "d_avg": 13.574433333333333,
            "d_kpx": 0.625,
            "kpi_name": "原料粉磨5B线电单耗",
            "shift1_avg": 12.9688625,
            "shift1_kavg": 1,
            "shift1_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift2_avg": 13.890775,
            "shift2_kavg": 0.5,
            "shift2_klist": [
                0,
                0,
                0,
                1,
                0,
                1,
                1,
                1
            ],
            "shift3_avg": 13.8636625,
            "shift3_kavg": 0.375,
            "shift3_klist": [
                0,
                0,
                1,
                0,
                0,
                1,
                1,
                0
            ],
            "std": 14,
            "tag": "YLFM5B_ECONS",
            "unit": "kW/t"
        },
        {
            "d_avg": 1502.0008333333335,
            "d_kpx": 0.8333333333333334,
            "kpi_name": "煤粉磨5A线电功率",
            "shift1_avg": 1467.6287500000003,
            "shift1_kavg": 1,
            "shift1_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift2_avg": 1522.3124999999998,
            "shift2_kavg": 0.75,
            "shift2_klist": [
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                1
            ],
            "shift3_avg": 1516.0612500000002,
            "shift3_kavg": 0.75,
            "shift3_klist": [
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "std": 1560,
            "tag": "MFZB5A_ERATE",
            "unit": "kW"
        },
        {
            "d_avg": 37.721375,
            "d_kpx": 0.5,
            "kpi_name": "煤粉磨5A线台时",
            "shift1_avg": 36.92575,
            "shift1_kavg": 0.125,
            "shift1_klist": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1
            ],
            "shift2_avg": 37.474625,
            "shift2_kavg": 0.375,
            "shift2_klist": [
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1
            ],
            "shift3_avg": 38.76375,
            "shift3_kavg": 1,
            "shift3_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "std": 10000,
            "tag": "MFZB5A_PRATE",
            "unit": "t/h"
        },
        {
            "d_avg": 39.817495833333346,
            "d_kpx": 0.7083333333333334,
            "kpi_name": "煤粉磨5A线电单耗",
            "shift1_avg": 39.7445,
            "shift1_kavg": 0.75,
            "shift1_klist": [
                1,
                1,
                0,
                1,
                1,
                1,
                0,
                1
            ],
            "shift2_avg": 40.5988625,
            "shift2_kavg": 0.625,
            "shift2_klist": [
                0,
                1,
                1,
                1,
                1,
                0,
                0,
                1
            ],
            "shift3_avg": 39.10912499999999,
            "shift3_kavg": 0.75,
            "shift3_klist": [
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "std": 41,
            "tag": "MFZB5A_ECONS",
            "unit": "kW/t"
        },
        {
            "d_avg": 1360.8538095238093,
            "d_kpx": 0.9047619047619048,
            "kpi_name": "煤粉磨5B线电功率",
            "shift1_avg": 1457.815,
            "shift1_kavg": 1,
            "shift1_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift2_avg": 1287.0749999999998,
            "shift2_kavg": 1,
            "shift2_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                null,
                null
            ],
            "shift3_avg": 1313.28,
            "shift3_kavg": 0.7142857142857143,
            "shift3_klist": [
                null,
                1,
                1,
                1,
                1,
                1,
                0,
                0
            ],
            "std": 1560,
            "tag": "MFZB5B_ERATE",
            "unit": "kW"
        },
        {
            "d_avg": 33.86161904761905,
            "d_kpx": 0.5714285714285714,
            "kpi_name": "煤粉磨5B线台时",
            "shift1_avg": 37.287124999999996,
            "shift1_kavg": 0.75,
            "shift1_klist": [
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                1
            ],
            "shift2_avg": 30.817666666666668,
            "shift2_kavg": 0.16666666666666666,
            "shift2_klist": [
                0,
                0,
                0,
                0,
                1,
                0,
                null,
                null
            ],
            "shift3_avg": 32.55585714285714,
            "shift3_kavg": 0.7142857142857143,
            "shift3_klist": [
                null,
                0,
                0,
                1,
                1,
                1,
                1,
                1
            ],
            "std": 10000,
            "tag": "MFZB5B_PRATE",
            "unit": "t/h"
        },
        {
            "d_avg": 39.53053157894737,
            "d_kpx": 0.8421052631578947,
            "kpi_name": "煤粉磨5B线电单耗",
            "shift1_avg": 39.097525,
            "shift1_kavg": 1,
            "shift1_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift2_avg": 39.30702,
            "shift2_kavg": 0.8,
            "shift2_klist": [
                0,
                1,
                1,
                1,
                1,
                null,
                null,
                null
            ],
            "shift3_avg": 40.29413333333334,
            "shift3_kavg": 0.6666666666666666,
            "shift3_klist": [
                null,
                null,
                0,
                1,
                1,
                1,
                1,
                0
            ],
            "std": 41,
            "tag": "MFZB5B_ECONS",
            "unit": "kW/t"
        },
        {
            "d_avg": 1440,
            "d_kpx": 0.20833333333333334,
            "kpi_name": "5#窑脱硫工序电功率",
            "shift1_avg": 1450,
            "shift1_kavg": 0.25,
            "shift1_klist": [
                0,
                0,
                0,
                0,
                1,
                1,
                0,
                0
            ],
            "shift2_avg": 1420,
            "shift2_kavg": 0.25,
            "shift2_klist": [
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                1
            ],
            "shift3_avg": 1450,
            "shift3_kavg": 0.125,
            "shift3_klist": [
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                0
            ],
            "std": 1400,
            "tag": "TLGX05_ERATE",
            "unit": "kW"
        },
        {
            "d_avg": 2.8463374999999993,
            "d_kpx": 0.875,
            "kpi_name": "5#窑脱硫工序电单耗",
            "shift1_avg": 2.87734,
            "shift1_kavg": 0.75,
            "shift1_klist": [
                1,
                0,
                0,
                1,
                1,
                1,
                1,
                1
            ],
            "shift2_avg": 2.80166125,
            "shift2_kavg": 1,
            "shift2_klist": [
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1
            ],
            "shift3_avg": 2.8600112499999994,
            "shift3_kavg": 0.875,
            "shift3_klist": [
                1,
                1,
                1,
                1,
                0,
                1,
                1,
                1
            ],
            "std": 3,
            "tag": "TLGX05_ECONS",
            "unit": "t/h"
        }
    ]
};

export {
    getColumns,
    mockData,
};
