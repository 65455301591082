import * as echarts from 'echarts';

const renderLineChart = (refNode, config, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        if (!data || !config) {
            instance.clear();
            return;
        };

        const datas = (config ?? [])
            .map(item => {
                const { key } = item;
                return Object.assign({}, item, { values: data?.[key] ?? [] });
            });

        const xData = (datas?.[0]?.values ?? []).map((item, index) => index);

        instance.setOption({
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                containLabel: true,
                left: 60,
                top: 48,
                right: 60,
                bottom: 64,
            },
            xAxis: {
                type: 'category',
                data: xData,
                boundaryGap: false,
                axisLabel: {
                    color: 'rgba(77, 193, 215, 1)',
                    // interval: 0,
                    fontSize: 18,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(5, 78, 138, 1)',
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: 'rgba(77, 193, 215, 1)',
                    fontSize: 18,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(5, 78, 138, 1)',
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
            },
            series: (datas ?? [])
                .map(item => {
                    const { values, color } = item;
                    return {
                        data: values ?? [],
                        type: 'line',
                        smooth: true,
                        symbol: "none",
                        itemStyle: {
                            color: `rgba(${color.join(",")}, 1)`,
                        },
                        areaStyle: {
                            color: `rgba(${color.join(",")}, 0.2)`,
                        },
                    };
                }),
        });
    };
};

const colors = [[244, 90, 102], [55, 195, 215], [251, 152, 49], [64, 160, 119]];
const mockList = [
    {
        name: "燃料燃烧排放量",
        value: 9999,
        unit: "tCO₂",
        color: colors[0],
        key: "CO2_Ck_24h",
    },
    {
        name: "过程排放量",
        value: 9999,
        unit: "tCO₂",
        color: colors[1],
        key: "CO2_Prc_24h",
    },
    {
        name: "购入电力排放量",
        value: 9999,
        unit: "tCO₂",
        color: colors[2],
        key: "CO2_Ele_24h",
    },
    {
        name: "余热发电排放量",
        value: 9999,
        unit: "tCO₂",
        color: colors[3],
        key: "CO2_RC_24h",
    },
];

export {
    renderLineChart,
    mockList,
};
