import React, { Component } from 'react';
import { ConfigProvider } from "antd";
import zhCN from 'antd/lib/locale-provider/zh_CN';
import "moment/locale/zh-cn"
import { HashRouter, Switch, Redirect, Route } from "react-router-dom";

// 海螺大屏
import ScreenCarbon from 'containers/ThreeScreen/ScreenCarbon';
import ScreenTrend from 'containers/ThreeScreen/ScreenTrend';
import ScreenMain from 'containers/ThreeScreen/ScreenMain';
import ThreeScreen from 'containers/ThreeScreen';

class App extends Component {
    render() {
        return (
            <ConfigProvider locale={zhCN}>
                <HashRouter>
                    <Switch>
                        <Redirect exact from="/" to="/ThreeScreen" />
                        <Route exact path="/ScreenCarbon" component={ScreenCarbon} />
                        <Route exact path="/ScreenTrend" component={ScreenTrend} />
                        <Route exact path="/ScreenMain" component={ScreenMain} />
                        <Route exact path="/ThreeScreen" component={ThreeScreen} />
                    </Switch>
                </HashRouter>
            </ConfigProvider>
        );
    }
}

export default App;
