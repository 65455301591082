import style from './index.module.scss';
import Chart from './Chart';
import { significantNum } from 'common/utils';

const Page = (props) => {
    const { data, list } = props;

    return (
        <div className={style['wrapper']}>
            <div className="content_wrapper scroll_bar_restyle">
                <div className="content">
                    {
                        (list ?? [])
                            .map((item, index) => {
                                const { name, keys } = item;
                                return (
                                    <div className="item" key={index}>
                                        <div className="top">
                                            <div className="title">{name}</div>
                                            {
                                                (keys ?? [])
                                                    .map((keyItem, keyIndex) => {
                                                        const { name, unit, key } = keyItem;
                                                        const value = data?.dataList?.[9]?.[key] ?? null;
                                                        const p_v = typeof value === 'number' ? significantNum(value) : "--";
                                                        return (
                                                            <div className="item" key={keyIndex}>
                                                                <div className="bar" style={{ backgroundColor: keyIndex === 0 ? "rgba(251, 152, 49, 1)" : "rgba(39, 229, 240, 1)" }}></div>
                                                                <div className="name">{name}</div>
                                                                <div className="value" style={{ color: keyIndex === 0 ? "rgba(251, 152, 49, 1)" : "rgba(39, 229, 240, 1)" }}>{p_v ?? ""}</div>
                                                                <div className="unit">{unit}</div>
                                                            </div>
                                                        );
                                                    })
                                            }
                                        </div>
                                        <div className="bottom">
                                            <div className="left">近10天趋势</div>
                                            <div className="right">
                                                <Chart data={data} {...item} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        </div>
    );
};

export default Page;
