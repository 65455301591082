import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import { renderLineChart } from './utils';

const Page = (props) => {
    const { data } = props;

    const refBottom = useRef(null);
    useEffect(() => {
        renderLineChart(refBottom.current, data);
    }, [data]);

    return (
        <div className={style['wrapper']}>
            <div className="top">
                <div className="left">当日产量与排放量趋势</div>
                <div className="right">
                    <div className='item'>
                        <div className='line'></div>
                        <div className='name'>产量</div>
                    </div>
                    <div className='item'>
                        <div className='bar'></div>
                        <div className='name'>排放量</div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="chart" ref={refBottom}></div>
            </div>
        </div>
    );
};

export default Page;
