import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import { renderLineChart, mockList } from './utils';

const Page = (props) => {
    const { data } = props;

    const refBottom = useRef(null);
    useEffect(() => {
        renderLineChart(refBottom.current, mockList, data);
    }, [data]);

    return (
        <div className={style['wrapper']}>
            <div className="top">
                <div className="left">当日各排放源趋势</div>
                <div className="right">
                    {
                        (mockList ?? [])
                            .map((item, index) => {
                                const { color, name } = item;
                                return (
                                    <div className='item' key={index}>
                                        <div className='bar' style={{ backgroundColor: `rgba(${color.join(",")},1)` }}></div>
                                        <div className='name'>{name}</div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
            <div className="bottom">
                <div className="chart" ref={refBottom}></div>
            </div>
        </div>
    );
};

export default Page;
