import { significantNum } from 'common/utils';
import * as echarts from 'echarts';

const renderLineChart = (refNode, data, keys, doubleYAxis) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        data = data?.dataList ?? null;

        if (!data || !keys) {
            instance.clear();
            return;
        };

        const xData = data.map((item, index) => index);

        instance.setOption({
            tooltip: {
                trigger: 'axis',
                formatter: vals => {
                    // console.log(vals);
                    const name = vals?.[0]?.name ?? "--";
                    const retStr = name + (
                        (vals ?? [])
                            .map(({ marker, value }) => {
                                const p_v = typeof value === 'number' ? significantNum(value) : "--";
                                return "<br>" + marker + " " + p_v;
                            })
                            .join("")
                    );
                    return retStr;
                },
            },
            grid: {
                containLabel: true,
                left: 24,
                top: 12,
                right: 26,
                bottom: 20,
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
            },
            yAxis: new Array(doubleYAxis === true ? 2 : 1)
                .fill(null)
                .map(() => {
                    return {
                        type: 'value',
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                    };
                }),
            series: keys
                .map((item, index) => {
                    const { key } = item;
                    return Object.assign(
                        {},
                        {
                            data: data.map(({ [key]: v }) => v),
                            type: 'bar',
                            barWidth: 12,
                            itemStyle: index === 0 ? {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: "rgba(251, 152, 49, 1)" },
                                        { offset: 1, color: 'rgba(251, 152, 49, 0.01)' },
                                    ],
                                ),
                                borderRadius: 16,
                            } : {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: "rgba(15, 229, 240, 1)" },
                                        { offset: 1, color: 'rgba(15, 229, 240, 0.01)' },
                                    ],
                                ),
                                borderRadius: [16, 16, 0, 0],
                            },
                        },
                        doubleYAxis === true ? {
                            yAxisIndex: index === 0 ? 0 : 1,
                        } : {},
                    );
                }),
        });
    };
};

const mockList = [
    {
        name: "燃料燃烧排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "过程排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "购入电力排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "余热发电排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "燃料燃烧排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "过程排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "购入电力排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "余热发电排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "燃料燃烧排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "过程排放量",
        value: 9999,
        unit: "tCO₂",
    },
];

export {
    renderLineChart,
    mockList,
};
