import React, { useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import Layout from '../Layout';
import { getBlocks } from './utils';
import BlockThree from './BlockThree/ThreeComp';
import { post } from 'common/utils';
import moment from 'moment';

const Page = (props) => {
    const [datetime] = useState(() => {
        const currTime = moment()
        if (parseInt(currTime.format("HH")) >= 12) {
            return currTime.subtract(1, 'days').format("YYYY-MM-DD")
        } else {
            return currTime.subtract(2, 'days').format("YYYY-MM-DD")
        }
    })

    const [datas, setDatas] = useState(null);
    useEffect(() => {
        // const datetime = "2021-12-17";
        Promise.all([
            post(process.env.REACT_APP_SERVER_PATH + "conch_wuhu/main_prod", { datetime }),
            post(process.env.REACT_APP_SERVER_PATH + "conch_wuhu/main_energy", { datetime }),
            post(process.env.REACT_APP_SERVER_PATH + "conch_wuhu/main_elestat", { datetime }),
            post(process.env.REACT_APP_SERVER_PATH + "conch_wuhu/main_quality", { datetime }),
            post(process.env.REACT_APP_SERVER_PATH + "conch_wuhu/main_pwrgen", { datetime }),
        ])
            .then(retDatas => {
                const n_datas = (retDatas ?? [])
                    .map(retData => {
                        if (retData?.data?.errcode === 0) {
                            return retData?.data?.result?.result ?? null;
                        };
                        return null;
                    });
                console.log(n_datas);
                setDatas(n_datas);
            });
    }, [datetime]);

    const [blocks, setBlocks] = useState(null);

    useEffect(() => {
        setBlocks(getBlocks(datas));
    }, [datas]);

    const title = useMemo(() => {
        return moment(datetime).format("指标概览【YYYY年MM月DD日】")
    }, [datetime])

    return (
        <Layout
            title={title}
            width={3840}
            height={1728}
        >
            <div className={style['wrapper']}>
                <div className="top">
                    <div className="item">
                        {blocks?.prod ?? null}
                    </div>
                    <div className="item_mid">
                        <BlockThree />
                    </div>
                    <div className="item">
                        {blocks?.cost ?? null}
                    </div>
                </div>
                <div className="bottom">
                    <div className="item">
                        {blocks?.elec ?? null}
                    </div>
                    <div className="item">
                        {blocks?.qlty ?? null}
                    </div>
                    <div className="item">
                        {blocks?.pwrgen ?? null}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Page;
