import { significantNum } from 'common/utils';
import React, { useEffect, useMemo, useRef } from 'react';
import style from './index.module.scss';
import { renderLineChart, renderGaugeChart } from './utils';

const Page = (props) => {
    const { data, chartData } = props;

    const refBottom = useRef(null);
    const refTopLeft = useRef(null);
    const refTopRight = useRef(null);
    useEffect(() => {
        renderLineChart(refBottom.current, chartData);
        renderGaugeChart(refTopLeft.current, data?.mm_plan_ratio ?? null);
        renderGaugeChart(refTopRight.current, data?.yy_plan_ratio ?? null);
    }, [data, chartData]);

    const p_data = useMemo(() => {
        if (!!data) {
            const { L05_ECO2_M, L05_ECO2_Y } = data;
            return [L05_ECO2_M, L05_ECO2_Y]
                .map(v => typeof v === 'number' ? significantNum(v) : "--");
        };
        return null;
    }, [data]);

    return (
        <div className={style['wrapper']}>
            <div className="top">
                <div className="item">
                    <div className="left">
                        <div className="title">当月累计</div>
                        <div className="v_wrapper">
                            <div className="value">{p_data?.[0] ?? ""}</div>
                        </div>
                        <div className="unit">tCO₂</div>
                    </div>
                    <div className="right">
                        <div className="chart" ref={refTopLeft}></div>
                    </div>
                </div>
                <div className="item">
                    <div className="left">
                        <div className="title">当年累计</div>
                        <div className="v_wrapper">
                            <div className="value">{p_data?.[1] ?? ""}</div>
                        </div>
                        <div className="unit">tCO₂</div>
                    </div>
                    <div className="right">
                        <div className="chart" ref={refTopRight}></div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="title">当年月趋势</div>
                <div className="chart" ref={refBottom}></div>
            </div>
        </div>
    );
};

export default Page;
