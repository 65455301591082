import { post } from 'common/utils';
import { useEffect, useState } from 'react';
import style from './index.module.scss';
import { getColumns } from './utils';
import moment from 'moment';

const Page = (props) => {
    const [datetime] = useState(() => {
        const currTime = moment()
        if (parseInt(currTime.format("HH")) >= 12) {
            return currTime.subtract(1, 'days').format("YYYY-MM-DD")
        } else {
            return currTime.subtract(2, 'days').format("YYYY-MM-DD")
        }
    })

    const [columns] = useState(() => {
        return getColumns();
    });

    const [data, setData] = useState(null);
    useEffect(() => {
        post(process.env.REACT_APP_SERVER_PATH + "conch_wuhu/kpi_board", {
            day: datetime,
            server_env: 0,
        })
            .then(retData => {
                let n_data = null;
                if (retData?.data?.errcode === 0) {
                    n_data = retData?.data?.result ?? null;
                };
                setData(n_data);
            });
    }, [datetime]);

    return (
        <div className={style['wrapper']}>
            <div className="content_wrapper scroll_bar_restyle">
                <table>
                    <thead>
                        <tr>
                            {
                                (columns ?? [])
                                    .map((item, index) => {
                                        let { title } = item;
                                        if (typeof title === 'function') {
                                            title = title();
                                        };
                                        return (
                                            <th key={index}>{title}</th>
                                        );
                                    })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (data ?? [])
                                .map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            {
                                                (columns ?? [])
                                                    .map((column, cIndex) => {
                                                        const { dataIndex, render } = column;
                                                        let t = item?.[dataIndex] ?? null;
                                                        if (typeof render === 'function') {
                                                            t = render(t);
                                                        };
                                                        return (
                                                            <td key={cIndex}>{t}</td>
                                                        );
                                                    })
                                            }
                                        </tr>
                                    );
                                })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Page;
