import { significantNum } from 'common/utils';
import * as echarts from 'echarts';

const renderLineChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        if (!data) {
            instance.clear();
            return;
        };
        const { L05_ECO2_mcum, L05_ECO2_mplan } = data;

        const xData = (L05_ECO2_mcum ?? []).map(({ m }) => m);

        instance.setOption({
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                containLabel: true,
                left: 30,
                top: 48,
                right: 30,
                bottom: 24,
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    color: 'rgba(77, 193, 215, 1)',
                    interval: 0,
                    fontSize: 18,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(5, 78, 138, 1)',
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: 'rgba(77, 193, 215, 1)',
                    fontSize: 18,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(5, 78, 138, 1)',
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
            },
            series: [
                Object.assign(
                    {},
                    {
                        data: (L05_ECO2_mcum ?? []).map(({ v }) => v),
                        type: 'bar',
                        barWidth: 16,
                        itemStyle: {
                            color: "rgba(18, 147, 240, 1)",
                            borderRadius: 2,
                        },
                    },
                ),
                Object.assign(
                    {},
                    {
                        data: (L05_ECO2_mplan ?? []).map(({ v }) => v),
                        type: 'bar',
                        barWidth: 16,
                        itemStyle: {
                            color: "rgba(52, 191, 227, 1)",
                            borderRadius: 2,
                        },
                    },
                ),
            ]
        });
    };
};

const renderGaugeChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        if (typeof data !== 'number') {
            instance.clear();
            return;
        };

        const p_data = significantNum(data);

        instance.setOption({

            series: [{
                type: 'gauge',
                // startAngle: 180,
                // endAngle: 0,
                min: 0,
                max: 120,
                // splitNumber: 8,
                radius: '90%',
                // center: ['50%', '80%'],
                axisLine: {
                    lineStyle: {
                        width: 10,
                        color: [[24 / 120, 'rgba(15, 229, 240, 1)'], [96 / 120, 'rgba(0, 127, 198, 1)'], [120 / 120, 'rgba(250, 63, 67, 1)']],
                    }
                },
                // pointer: {
                //     icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                //     length: 18,
                //     width: 9,
                //     offsetCenter: [0, -50],
                //     itemStyle: {
                //         color: 'auto'
                //     }
                // },
                axisTick: {
                    length: 14,
                    lineStyle: {
                        color: 'auto',
                        width: 1,
                    },
                    // distance: 4,
                },
                // axisLabel: {
                //     color: '#727272',
                //     fontSize: 10,
                //     distance: 8,
                //     formatter: (value) => {
                //         if (typeof data !== 'number') {
                //             return "";
                //         };
                //         return value;
                //     },
                // },
                splitLine: {
                    length: 18,
                    lineStyle: {
                        color: 'auto',
                        width: 1,
                    },
                    // distance: 4,
                },
                detail: {
                    fontSize: 28,
                    // offsetCenter: [0, -5],
                    color: 'rgba(255, 255, 255, 1)',
                    formatter: (v) => {
                        return (typeof v === 'number' ? v : "--") + "%";
                    },
                },
                data: [{
                    value: p_data,
                }]
            }]
        });
    };
};

const colors = ["rgba(244, 90, 102, 1)", "rgba(55, 195, 215, 1)", "rgba(251, 152, 49, 1)", "rgba(64, 160, 119, 1)"];
const mockList = [
    {
        name: "燃料燃烧排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "过程排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "购入电力排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "余热发电排放量",
        value: 9999,
        unit: "tCO₂",
    },
];

export {
    renderLineChart,
    renderGaugeChart,
    colors,
    mockList,
};
