import { significantNum } from 'common/utils';
import moment from 'moment';
import { useMemo } from 'react';
import style from './index.module.scss';

const Page = (props) => {
    const { data } = props;

    const items = useMemo(() => {
        if (!!data) {
            return (
                Object.keys(data?.dataList?.[0] ?? {})
                    .filter(v => v !== "日期")
            )
                .filter((v, i) => i < 5);
        };
        return null;
    }, [data]);

    return (
        <div className={style['wrapper']}>
            <table>
                <thead>
                    <tr>
                        <th className="name"></th>
                        <th className="val"></th>
                        <th className="time"></th>
                        <th className="blank"></th>
                        {
                            new Array(9)
                                .fill(null)
                                .map((item, index) => {
                                    const time = data?.dataList?.[index + 1]?.["日期"] ?? null;
                                    let p_t = "--";
                                    const m_t = moment(time);
                                    if (m_t.isValid()) {
                                        p_t = m_t.format("HH:mm");
                                    };
                                    return (
                                        <th key={index}>{p_t}</th>
                                    );
                                })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        (items ?? [])
                            .map((item, index) => {
                                const time = data?.dataList?.[0]?.["日期"] ?? null;
                                const v = data?.dataList?.[0]?.[item] ?? null;
                                const p_v = typeof v === 'number' ? significantNum(v) : "--";
                                let p_t = "--";
                                const m_t = moment(time);
                                if (m_t.isValid()) {
                                    p_t = m_t.format("DD日 HH:mm");
                                };
                                return (
                                    <tr key={index}>
                                        <td className="name">{item}</td>
                                        <td className="val">{p_v}</td>
                                        <td className="time">({p_t})</td>
                                        <td className="blank"></td>
                                        {
                                            new Array(9)
                                                .fill(null)
                                                .map((tdItem, index) => {
                                                    // index
                                                    const dataObj = data?.dataList?.[index + 1] ?? null;
                                                    const value = dataObj?.[item] ?? null;
                                                    const p_v = typeof value === 'number' ? significantNum(value) : "--";
                                                    // const isRed = typeof value === 'number' && Math.random() > 0.9;
                                                    const isRed = false;
                                                    return (
                                                        <td key={index} className={"value" + (isRed ? " red" : "")}>{p_v}</td>
                                                    );
                                                })
                                        }
                                    </tr>
                                );
                            })
                    }
                </tbody>
            </table>
        </div>
    );
};

export default Page;
