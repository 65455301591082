import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import { renderLineChart } from './utils';

const Page = (props) => {
    const { data, keys, doubleYAxis } = props;

    const refNode = useRef(null);
    useEffect(() => {
        renderLineChart(refNode.current, data, keys, doubleYAxis);
    }, [keys, data, doubleYAxis]);

    return (
        <div className={style['wrapper']} ref={refNode}>
        </div>
    );
};

export default Page;
