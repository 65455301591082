import { significantNum } from 'common/utils';
import React, { useEffect, useMemo, useRef } from 'react';
import style from './index.module.scss';
import { renderLineChart, renderPieChart, colors, mockList } from './utils';

const Page = (props) => {
    const { data } = props;

    const refBottom = useRef(null);
    const refTop = useRef(null);
    useEffect(() => {
        renderLineChart(refBottom.current, data?.L05_ECO2_wk ?? null);
        renderPieChart(refTop.current, data ?? null);
    }, [data]);

    const p_data = useMemo(() => {
        if (!!data) {
            const { L05_ECO2_D, L05Ck_ECO2_D, L05Prc_ECO2_D, L05Ele_ECO2_D, L05RC_ECO2_D } = data;
            return [L05_ECO2_D, L05Ck_ECO2_D, L05Prc_ECO2_D, L05Ele_ECO2_D, L05RC_ECO2_D]
                .map(v => typeof v === 'number' ? significantNum(v) : "--");
        };
        return null;
    }, [data]);

    return (
        <div className={style['wrapper']}>
            <div className="top">
                <div className="left">
                    <div className="title">当日总排放量</div>
                    <div className="v_wrapper">
                        <div className="value">{p_data?.[0] ?? ""}</div>
                        <div className="unit">tCO₂</div>
                    </div>
                    <div className="subtitle">#5线</div>
                </div>
                <div className="right">
                    <div className="left">
                        <div className="chart" ref={refTop}></div>
                    </div>
                    <div className="right">
                        {
                            (mockList ?? [])
                                .map((item, index) => {
                                    const color = colors[index % colors.length];
                                    const { name, unit } = item;
                                    const value = p_data?.[index + 1] ?? "";
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="icon" style={{ backgroundColor: color }}></div>
                                            <div className="title">{name}</div>
                                            <div className="value">{value}</div>
                                            <div className="unit">{unit}</div>
                                        </React.Fragment>
                                    );
                                })
                        }
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="title">近7日趋势</div>
                <div className="chart" ref={refBottom}></div>
            </div>
        </div>
    );
};

export default Page;
