import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import { colors, renderChartBar, renderChartPie } from './utils';

const Page = (props) => {
    const { data, barTitle } = props;

    const refCircle = useRef(null);
    const refBar = useRef(null);
    useEffect(() => {
        renderChartBar(refBar.current, data);
        renderChartPie(refCircle.current, data);
    }, [data]);

    return (
        <div className={style['wrapper']}>
            <div className="top">
                {
                    (
                        Object.keys(data?.dataList?.[0] ?? {})
                            .filter(v => v !== "日期")
                    )
                        .map((item, index) => {
                            return (
                                <div className="item" key={index}>
                                    <div className="bar" style={{ backgroundColor: colors[index % colors.length] }}></div>
                                    <div className="name">{item}</div>
                                </div>
                            );
                        })
                }
            </div>
            <div className="bottom">
                <div className="left">
                    <div className="top">用电量占比</div>
                    <div className="bottom" ref={refCircle}></div>
                </div>
                <div className="right">
                    <div className="top">{barTitle ?? "趋势"}</div>
                    <div className="bottom" ref={refBar}></div>
                </div>
            </div>
        </div>
    );
};

export default Page;
